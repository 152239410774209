import React, { useState, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import '../components/Preview/Preview.css';
// import printJS from 'print-js'


import domtoimage from 'dom-to-image';





const Product = () => {
  const location = useLocation();
  const data = location.state;
  const [redirctTo, setRedirctTo] = useState(false); // your state value to manipulate
  const [acceptTo, setAcceptTo] = useState(false); // your state value to manipulate
  const [news, setNews] = useState(''); // your state value to manipulate


  function returnPhoto(){
    setRedirctTo(true)
  }
  function acceptPhoto(){
    setAcceptTo(true)
  }

  useEffect(()=>{
    domtoimage.toJpeg(document.getElementById('print-preview'), { quality: 1 })
    .then(function (dataUrl) {
      // var link = document.createElement('a');
      // link.download = 'my-image-name.jpeg';
      // link.href = dataUrl;
      // link.click();
      setNews(dataUrl);
    });
  }, [setNews]) // <-- empty dependency array


  
  // state object contains the data
  if(redirctTo){
    return (<Navigate to="/photo-booth"  />);

  } else{
    if(acceptTo){      
      return (
          <Navigate to="/photo-booth-print" state={news} />
      );
    } else{
      return (
        <div className='preview-container'>
            <div className='preview-container__print-preview-wrapper' id="print-preview">
              <div className='preview-container__print-preview' id="print-preview">
                <img alt="Photobooth" src={data.photoData}></img>
                <img alt="Photobooth" src={data.photoData}></img>
              </div>
            </div>
            <div className='preview-container__preview'>
                <button className='preview-container__preview-back' onClick={returnPhoto}></button>
                <div className='preview-container__preview-container'>
                    <img alt="Photobooth" src={data.photoData}></img>
                    <div className='preview-container__preview-container-text'>Love It Or Hate It? <br></br>We Won't Judge.</div>
                </div>
                <button className='preview-container__preview-print' onClick={acceptPhoto}></button>
            </div>
        </div>
      );
    }
  }
}
export default Product;